/* eslint-disable */
// @ts-nocheck

import {
  AutonumberFieldType,
  BooleanFieldType,
  CountFieldType,
  CreatedByFieldType,
  CreatedOnFieldType,
  DateFieldType,
  DurationFieldType,
  EmailFieldType,
  FieldType,
  FileFieldType,
  FormulaFieldType,
  LastModifiedByFieldType,
  LastModifiedFieldType,
  LinkRowFieldType,
  LongTextFieldType,
  LookupFieldType,
  MultipleCollaboratorsFieldType,
  MultipleSelectFieldType,
  NumberFieldType,
  PasswordFieldType,
  PhoneNumberFieldType,
  RatingFieldType,
  RollupFieldType,
  SingleSelectFieldType,
  TextFieldType,
  URLFieldType,
  UUIDFieldType,
} from '../baserowImport/baserow/modules/database/fieldTypes'

export function discoverFieldTypes() {
  const fieldTypes = new Map<string, typeof FieldType>()

  const fieldTypeClasses = [
    TextFieldType,
    LongTextFieldType,
    LinkRowFieldType,
    NumberFieldType,
    RatingFieldType,
    BooleanFieldType,
    DateFieldType,
    LastModifiedFieldType,
    CreatedOnFieldType,
    LastModifiedByFieldType,
    CreatedByFieldType,
    DurationFieldType,
    URLFieldType,
    EmailFieldType,
    FileFieldType,
    SingleSelectFieldType,
    MultipleSelectFieldType,
    PhoneNumberFieldType,
    FormulaFieldType,
    CountFieldType,
    RollupFieldType,
    LookupFieldType,
    MultipleCollaboratorsFieldType,
    UUIDFieldType,
    AutonumberFieldType,
    PasswordFieldType,
  ]

  for (const fieldTypeClass of fieldTypeClasses) {
    const type = fieldTypeClass.getType()
    if (type) {
      fieldTypes.set(type, fieldTypeClass)
    }
  }

  return fieldTypes
}
