/* eslint-disable */
// @ts-nocheck

import Papa from 'papaparse'

import { BaserowRegistry } from './baserowRegistry'
import { discoverFieldTypes } from './fieldTypeDiscovery'
import { discoverFilterTypes } from './filterTypeDiscovery'
import { discoverFormulaTypes } from './formulaTypeDiscovery'

class PapaWrapper {
  arrayToString(arr: any[], options: any = { delimiter: ',' }): string {
    return Papa.unparse([arr], options)
  }

  stringToArray(str: string): any[] {
    const result = Papa.parse(str, {
      delimiter: ',',
      skipEmptyLines: true,
    })
    return result.data[0] || []
  }
}

export class BaserowRegistryAdapter {
  private registry: BaserowRegistry
  private i18n: { t: (key: string) => string }
  private papaWrapper: PapaWrapper

  constructor() {
    this.registry = new BaserowRegistry()
    this.i18n = {
      t: (key: string) => key,
    }
    this.papaWrapper = new PapaWrapper()
    this.initializeRegistry()
  }

  public initializeRegistry() {
    const fieldTypes = discoverFieldTypes()
    for (const [type, FieldType] of fieldTypes.entries()) {
      const fieldType = new FieldType({ app: this.app })
      this.registry.registerFieldType(fieldType)
    }

    const filterTypes = discoverFilterTypes()
    for (const FilterType of filterTypes) {
      const filterType = new FilterType({ app: this.app })
      if (typeof FilterType.getType === 'function') {
        filterType.type = FilterType.getType()
      }
      this.registry.registerViewFilterType(filterType)
    }

    const formulaTypes = discoverFormulaTypes()
    for (const FormulaType of formulaTypes) {
      const formulaType = new FormulaType({ app: this.app })
      if (typeof FormulaType.getType === 'function') {
        formulaType.type = FormulaType.getType()
      }
      this.registry.registerFormulaType(formulaType)
    }

    return this.registry
  }

  get(type: string, name: string) {
    return this.registry.get(type, name)
  }

  get app(): {
    $registry: BaserowRegistryAdapter
    $papa: PapaWrapper
    i18n: { t: (key: string) => string }
  } {
    return {
      $registry: this,
      $papa: this.papaWrapper,
      i18n: this.i18n,
    }
  }
}

export const registryAdapter = new BaserowRegistryAdapter()
