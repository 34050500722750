import {
  BaserowFormulaTextType,
  BaserowFormulaNumberType,
  BaserowFormulaBooleanType,
  BaserowFormulaDateType,
  BaserowFormulaCharType,
  BaserowFormulaSingleSelectType,
  BaserowFormulaMultipleSelectType,
  BaserowFormulaArrayType,
  BaserowFormulaDurationType,
} from '../baserowImport/baserow/modules/database/formula/formulaTypes'

export function discoverFormulaTypes() {
  const formulaTypeClasses = [
    BaserowFormulaTextType,
    BaserowFormulaNumberType,
    BaserowFormulaBooleanType,
    BaserowFormulaDateType,
    BaserowFormulaCharType,
    BaserowFormulaSingleSelectType,
    BaserowFormulaMultipleSelectType,
    BaserowFormulaArrayType,
    BaserowFormulaDurationType,
  ]

  return formulaTypeClasses.filter((FormulaClass) => {
    try {
      return (
        typeof FormulaClass.getType === 'function' &&
        FormulaClass.getType() !== null
      )
    } catch {
      return false
    }
  })
}
