/* eslint-disable */
// @ts-nocheck

import {
  HasAllValuesEqualViewFilterType,
  HasAnySelectOptionEqualViewFilterType,
  HasDateAfterViewFilterType,
  HasDateBeforeViewFilterType,
  HasDateEqualViewFilterType,
  HasDateOnOrAfterViewFilterType,
  HasDateOnOrBeforeViewFilterType,
  HasDateWithinViewFilterType,
  HasEmptyValueViewFilterType,
  HasNoneSelectOptionEqualViewFilterType,
  HasNotDateAfterViewFilterType,
  HasNotDateBeforeViewFilterType,
  HasNotDateEqualViewFilterType,
  HasNotDateOnOrAfterViewFilterType,
  HasNotDateOnOrBeforeViewFilterType,
  HasNotDateWithinViewFilterType,
  HasNotEmptyValueViewFilterType,
  HasNotValueContainsViewFilterType,
  HasNotValueContainsWordViewFilterType,
  HasNotValueEqualViewFilterType,
  HasNotValueHigherThanOrEqualViewFilterType,
  HasNotValueHigherThanViewFilterType,
  HasNotValueLowerThanOrEqualViewFilterType,
  HasNotValueLowerThanViewFilterType,
  HasValueContainsViewFilterType,
  HasValueContainsWordViewFilterType,
  HasValueEqualViewFilterType,
  HasValueHigherThanOrEqualViewFilterType,
  HasValueHigherThanViewFilterType,
  HasValueLengthIsLowerThanViewFilterType,
  HasValueLowerThanOrEqualViewFilterType,
  HasValueLowerThanViewFilterType,
} from '../baserowImport/baserow/modules/database/arrayViewFilters'
import {
  BooleanViewFilterType,
  ContainsNotViewFilterType,
  ContainsViewFilterType,
  ContainsWordViewFilterType,
  DateEqualsDayOfMonthViewFilterType,
  DateIsAfterMultiStepViewFilterType,
  DateIsBeforeMultiStepViewFilterType,
  DateIsEqualMultiStepViewFilterType,
  DateIsNotEqualMultiStepViewFilterType,
  DateIsOnOrAfterMultiStepViewFilterType,
  DateIsOnOrBeforeMultiStepViewFilterType,
  DateIsWithinMultiStepViewFilterType,
  DateMultiStepViewFilterType,
  DoesntContainWordViewFilterType,
  EmptyViewFilterType,
  EqualViewFilterType,
  FilenameContainsViewFilterType,
  FilesLowerThanViewFilterType,
  HasFileTypeViewFilterType,
  HigherThanOrEqualViewFilterType,
  HigherThanViewFilterType,
  IsEvenAndWholeViewFilterType,
  LengthIsLowerThanViewFilterType,
  LinkRowContainsFilterType,
  LinkRowHasFilterType,
  LinkRowHasNotFilterType,
  LinkRowNotContainsFilterType,
  LowerThanOrEqualViewFilterType,
  LowerThanViewFilterType,
  MultipleCollaboratorsHasFilterType,
  MultipleCollaboratorsHasNotFilterType,
  MultipleSelectHasFilterType,
  MultipleSelectHasNotFilterType,
  NotEmptyViewFilterType,
  NotEqualViewFilterType,
  NumericComparisonViewFilterType,
  SingleSelectEqualViewFilterType,
  SingleSelectIsAnyOfViewFilterType,
  SingleSelectIsNoneOfViewFilterType,
  SingleSelectNotEqualViewFilterType,
  UserIsFilterType,
  UserIsNotFilterType,
} from '../baserowImport/baserow/modules/database/viewFilters'

export function discoverFilterTypes() {
  const filterTypeClasses = [
    // Basic filter types
    EqualViewFilterType,
    NotEqualViewFilterType,
    ContainsViewFilterType,
    ContainsNotViewFilterType,
    EmptyViewFilterType,
    NotEmptyViewFilterType,

    // Numeric comparison filter types
    HigherThanViewFilterType,
    HigherThanOrEqualViewFilterType,
    LowerThanViewFilterType,
    LowerThanOrEqualViewFilterType,
    IsEvenAndWholeViewFilterType,
    HasNotValueLowerThanViewFilterType,
    HasValueLengthIsLowerThanViewFilterType,
    NumericComparisonViewFilterType,

    // User filter types
    UserIsFilterType,
    UserIsNotFilterType,

    // File filter types
    FilenameContainsViewFilterType,
    HasFileTypeViewFilterType,
    FilesLowerThanViewFilterType,

    // Text filter types
    ContainsWordViewFilterType,
    DoesntContainWordViewFilterType,
    LengthIsLowerThanViewFilterType,

    // Date filter types
    DateMultiStepViewFilterType,
    DateIsEqualMultiStepViewFilterType,
    DateIsNotEqualMultiStepViewFilterType,
    DateIsBeforeMultiStepViewFilterType,
    DateIsOnOrBeforeMultiStepViewFilterType,
    DateIsAfterMultiStepViewFilterType,
    DateIsOnOrAfterMultiStepViewFilterType,
    DateIsWithinMultiStepViewFilterType,
    DateEqualsDayOfMonthViewFilterType,
    HasDateEqualViewFilterType,
    HasDateBeforeViewFilterType,
    HasDateAfterViewFilterType,
    HasDateOnOrBeforeViewFilterType,
    HasDateOnOrAfterViewFilterType,
    HasDateWithinViewFilterType,
    HasNotDateEqualViewFilterType,
    HasNotDateBeforeViewFilterType,
    HasNotDateAfterViewFilterType,
    HasNotDateOnOrBeforeViewFilterType,
    HasNotDateOnOrAfterViewFilterType,
    HasNotDateWithinViewFilterType,

    // Select filter types
    SingleSelectEqualViewFilterType,
    SingleSelectNotEqualViewFilterType,
    SingleSelectIsAnyOfViewFilterType,
    SingleSelectIsNoneOfViewFilterType,
    MultipleSelectHasFilterType,
    MultipleSelectHasNotFilterType,

    // Collaborator filter types
    MultipleCollaboratorsHasFilterType,
    MultipleCollaboratorsHasNotFilterType,

    // Boolean filter type
    BooleanViewFilterType,

    // Link row filter types
    LinkRowHasFilterType,
    LinkRowHasNotFilterType,
    LinkRowContainsFilterType,
    LinkRowNotContainsFilterType,

    // Array filter types
    HasEmptyValueViewFilterType,
    HasNotEmptyValueViewFilterType,
    HasValueEqualViewFilterType,
    HasNotValueEqualViewFilterType,
    HasValueContainsViewFilterType,
    HasNotValueContainsViewFilterType,
    HasValueContainsWordViewFilterType,
    HasNotValueContainsWordViewFilterType,
    HasAllValuesEqualViewFilterType,
    HasValueHigherThanViewFilterType,
    HasNotValueHigherThanViewFilterType,
    HasValueHigherThanOrEqualViewFilterType,
    HasNotValueHigherThanOrEqualViewFilterType,
    HasValueLowerThanViewFilterType,
    HasValueLowerThanOrEqualViewFilterType,
    HasNotValueLowerThanOrEqualViewFilterType,
    HasAnySelectOptionEqualViewFilterType,
    HasNoneSelectOptionEqualViewFilterType,
  ]

  return filterTypeClasses.filter((FilterClass) => {
    try {
      // Check if the class has a valid getType method
      return (
        typeof FilterClass.getType === 'function' &&
        FilterClass.getType() !== null
      )
    } catch {
      return false
    }
  })
}
