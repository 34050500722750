import { useEffect, useState } from 'react'

import { captureEvent } from '@sentry/browser'
import type { DetailedTableRecord } from 'api/src/common/baserowImporterTypes'
import useWebSocket, { ReadyState } from 'react-use-websocket'

import { toast } from '@redwoodjs/web/toast'

import {
  createRecord,
  enrichRecords,
  updateRecord,
} from 'src/components/HubDash/lib/baserow/baserowApi'
import {
  filterRecordsInView,
  getRecordDecorationsInView,
} from 'src/components/HubDash/lib/baserow/brViewFilter'
import { loadBaserowData } from 'src/components/HubDash/lib/baserow/loadBaserowData'
import useHubDashStore from 'src/lib/stores/hubDashStore'

import type { BaserowFieldType, BaserowFormulaType } from '../enums'

interface LoadBaseDataParams {
  workspaceId: number
  baseId: number
  tableId: number
  viewId: number
  tableName: string
  viewName: string
  token: string
  config: any // not typed yet
}
const replaceData = async (loadBaseDataParams: LoadBaseDataParams) => {
  const result = await loadBaserowData({
    workspaceId: loadBaseDataParams.workspaceId,
    baseId: loadBaseDataParams.baseId,
    tableId: loadBaseDataParams.tableId,
    viewId: loadBaseDataParams.viewId,
    tableName: loadBaseDataParams.tableName,
    viewName: loadBaseDataParams.viewName,
    token: loadBaseDataParams.token,
    config: loadBaseDataParams.config,
  })
  return result
}

const handleMessage = async (params: {
  token: string
  lastMessage: { data: string }
  setValue
  records: {
    id: number
    commentCount: number
    notificationMode
    fields: any[]
  }[]
  activityValue
  setActivityValue
  viewData
  tableData: DetailedTableRecord
  workspaceId: number
  socketType: string
  setTable
  setView
  loadBaseDataParams: LoadBaseDataParams | null
  setBaserowErrorsState
  setRowUpdateFieldAndRowId
}) => {
  const {
    token,
    lastMessage,
    setValue,
    records,
    activityValue,
    setActivityValue,
    viewData,
    tableData,
    workspaceId,
    socketType,
    setTable,
    loadBaseDataParams,
    setView,
    setBaserowErrorsState,
    setRowUpdateFieldAndRowId,
  } = params

  const processRecords = (
    records: { id: number; commentCount: number; notificationMode: string }[],
    viewData,
    tableData,
    tableId: number,
  ) => {
    const enrichedRecords = enrichRecords({
      token,
      records,
      tableFieldsData: tableData,
      tableId: tableId,
      workspaceId: workspaceId,
    })
    let decoratedRecords = []
    if (viewData) {
      decoratedRecords = getRecordDecorationsInView(
        enrichedRecords,
        viewData,
        tableData,
      )
    } else {
      decoratedRecords = enrichedRecords
    }

    return decoratedRecords
  }

  const message = JSON.parse(lastMessage.data)
  switch (message.type) {
    case 'rows_updated': {
      if (socketType === 'card') {
        setRowUpdateFieldAndRowId({
          updatedFieldIds: message.updated_field_ids,
          rowIds: message.rows.map((row) => row.id),
        })
      }
      const updatedRows = message.rows
      setValue((prevRecords) => {
        const updateData = prevRecords
          .map((row) => {
            const updatedRow = updatedRows.find((r) => r?.id === row?.id)
            if (updatedRow) {
              const processedRow = processRecords(
                [updatedRow],
                viewData,
                tableData.fields,
                tableData.id,
              )
              if (socketType === 'record') {
                return processedRow[0]
              }

              const processedRowInView = filterRecordsInView(
                processedRow,
                viewData,
                tableData.fields,
              )
              return processedRowInView[0]
            } else {
              return row
            }
          })
          .filter((row) => row !== null && row !== undefined)

        return updateData
      })
      break
    }
    case 'rows_deleted': {
      const deletedRows = message.rows
      const deleteData = records.filter((row) => {
        const deletedRow = deletedRows.find((r) => r?.id === row?.id)
        if (deletedRow) {
          return false
        } else {
          return true
        }
      })
      setValue(deleteData)
      break
    }
    case 'rows_created': {
      if (socketType === 'card') {
        const createdRows = message.rows
        const processedRows = processRecords(
          createdRows,
          viewData,
          tableData.fields,
          tableData.id,
        )
        const processedRowsInView = filterRecordsInView(
          processedRows,
          viewData,
          tableData.fields,
        )
        const createdData = records.concat(processedRowsInView)
        setValue(createdData)
      }
      break
    }
    case 'row_comment_created':
    case 'row_comment_updated':
    case 'row_comment_deleted':
      if (socketType === 'record') {
        if (message.row_comment.table_id === tableData.id) {
          for (const record of records) {
            if (record?.id === message.row_comment.row_id) {
              setActivityValue([
                ...activityValue,
                {
                  ...message.row_comment,
                  type: 'comment',
                },
              ])
            }
          }
        }
      }
      break
    case 'row_history_updated':
      if (socketType === 'record') {
        for (const record of records) {
          if (record?.id === message.row_id) {
            setActivityValue([
              ...activityValue,
              {
                ...message.row_history_entry,
                type: 'revision',
              },
            ])
          }
        }
      }
      break
    case 'row_comments_notification_mode_updated':
      {
        //update card and record in real time when notification mode is updated
        const updatedRow = []
        for (const record of records) {
          if (record?.id === message.row_id) {
            record.notificationMode =
              message.mode === 'mentions' ? 'only' : 'all'
          }
          updatedRow.push(record)
        }
        setValue(updatedRow)
      }
      break
    case 'table_deleted':
      if (socketType === 'card') {
        if (message.table_id === tableData.id) {
          setBaserowErrorsState((prev) => {
            return [
              ...prev,
              {
                entity: 'table',
                message: `The table ${tableData.name} - ${tableData.id} was not found in the base`,
              },
            ]
          })
        }
      }
      break
    case 'field_deleted':
    case 'field_created':
    case 'field_updated':
    case 'field_restored':
      //field restored in baserow restores the value as well which is not possible with state updates, need to fetch everything again
      //reset in real time when field is restored
      if (socketType === 'card') {
        const result = await replaceData(loadBaseDataParams)
        setValue(result.records)
        setTable(result.table)
        setView(result.view)
      }
      break
    case 'view_deleted':
      if (socketType === 'card') {
        if (
          message.table_id === tableData.id &&
          message.view_id === viewData?.id
        ) {
          setBaserowErrorsState((prev) => {
            return [
              ...prev,
              {
                entity: 'view',
                message: `The view ${viewData.name} - ${viewData.id} was not found on the table ${tableData.name} - ${tableData.id}`,
              },
            ]
          })
        }
      }
      break
    default:
      break
  }
}

export const useBaserowRecordSocket = ({
  tableId,
  recordId,
  recordData,
  tableData,
  viewData,
}) => {
  const token = useHubDashStore((state) => state.token)
  const [record, setRecord] = useState([recordData])
  const [activityValue, setActivityValue] = useState([])

  const socketUrl =
    `wss://${process.env.BASEROW_URL.replace('https://', '')}/ws/core/?jwt_token=` +
    token
  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(socketUrl)

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState]

  useEffect(() => {
    if (token && tableId && recordId) {
      sendJsonMessage({
        page: 'row',
        table_id: tableId,
        row_id: recordId,
      })
      sendJsonMessage({
        page: 'table',
        table_id: tableId,
      })
    }
  }, [token, tableId, recordId])

  useEffect(() => {
    if (lastMessage !== null) {
      try {
        handleMessage({
          token,
          lastMessage,
          setValue: setRecord,
          records: record,
          viewData,
          tableData,
          workspaceId: null,
          socketType: 'record',
          activityValue,
          setActivityValue,
          setTable: () => {},
          loadBaseDataParams: null,
          setView: () => {},
          setBaserowErrorsState: () => {},
          setRowUpdateFieldAndRowId: () => {},
        })
      } catch (error) {
        captureEvent({
          message: 'handleMessage: Unhandled error',
          extra: {
            error,
            tableId,
            recordId,
            socketType: 'record',
          },
        })
        toast.error(
          'An unexpected error occurred. Please refresh the page at your earliest convenience.',
        )
      }
    }
  }, [lastMessage])

  useEffect(() => {
    //sync record data with the recordData prop after field updates
    //field updates lead to a full reload of the record data
    setRecord([recordData])
  }, [recordData])

  return {
    record: record[0],
    activityUpdates: activityValue,
    table: tableData,
    connectionStatus,
    lastMessage,
  }
}
export type CardSocketRecordField = {
  id: number
  type: BaserowFieldType
  name: string
  value: any
}
export type CardSocketRecord = {
  id: number
  getCellValue: <T extends string | object | any[]>(fieldName: string) => T
  getCellValueAsString: (fieldName: string) => string
  updateRecord: (recordData?: any) => Promise<any>
  decorators: any
  commentCount: number
  fields: CardSocketRecordField[]
}
export type CardSocketTableField = {
  id?: number
  type: BaserowFieldType
  name: string
  viewHidden?: boolean
  formula_type?: BaserowFormulaType
  primary?: boolean
  number_separator?: string
  number_prefix?: string
  number_suffix?: string
  number_decimal_places?: number
  date_include_time?: boolean
  date_show_tzinfo?: boolean
  date_force_timezone?: string
  date_time_format?: string
  target_field_id?: number
  read_only?: boolean
  array_formula_type?: string
  duration_format?: string
  max_value?: number
  rollup_function?: any
}

export type CardSocket = {
  records: CardSocketRecord[]
  table: { id: number; fields: CardSocketTableField[]; name: string }
  view: any
  base: any
  activeRecords?: any[]
  inactiveRecords?: any[]
  connectionStatus?: string
  lastMessage?: MessageEvent<any>
  createRecord?: typeof createRecord
  updateRecordById?: typeof updateRecord
  baserowErrorsState: any[]
}

export const useBaserowViewSocket = ({
  tableData,
  viewData,
  baserowData,
  workspaceId,
  baserowErrors,
}) => {
  const token = useHubDashStore((state) => state.token)
  const [records, setRecords] = useState([])
  const [table, setTable] = useState(null)
  const [view, setView] = useState(null)
  const [baserowErrorsState, setBaserowErrorsState] = useState(baserowErrors)
  const [setRowUpdateFieldAndRowId] = useHubDashStore((state) => [
    state.setRowUpdateFieldAndRowId,
  ])

  useEffect(() => {
    setBaserowErrorsState(baserowErrors || [])
  }, [baserowErrors])

  useEffect(() => {
    setRecords(baserowData?.records || [])
  }, [baserowData?.records])

  useEffect(() => {
    setTable(tableData || null)
  }, [tableData])

  useEffect(() => {
    setView(viewData || null)
  }, [viewData])

  const socketUrl =
    `wss://${process.env.BASEROW_URL.replace('https://', '')}/ws/core/?jwt_token=` +
    token
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl)

  useEffect(() => {
    if (token && tableData?.id) {
      sendMessage(`{"page":"table","table_id":${tableData?.id}}`)
    }
  }, [token, tableData?.id])

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState]

  const loadBaseDataParams: LoadBaseDataParams = {
    workspaceId: workspaceId,
    baseId: baserowData?.base?.id,
    tableId: baserowData?.table?.id,
    viewId: baserowData?.view?.id,
    tableName: tableData?.name,
    viewName: tableData?.view?.name,
    token: token,
    config: baserowData?.config,
  }

  useEffect(() => {
    if (lastMessage !== null) {
      try {
        handleMessage({
          token,
          lastMessage,
          setValue: setRecords,
          setBaserowErrorsState,
          records,
          viewData: view,
          tableData: table,
          workspaceId: baserowData?.config?.cardData?.workspace?.id,
          socketType: 'card',
          activityValue: [],
          setActivityValue: () => {},
          setTable,
          setView,
          loadBaseDataParams,
          setRowUpdateFieldAndRowId,
        })
      } catch (error) {
        captureEvent({
          message: 'handleMessage: Unhandled error',
          extra: {
            error,
            tableId: tableData?.id,
            workspaceId: baserowData?.config?.cardData?.workspace?.id,
            socketType: 'card',
          },
        })
        toast.error(
          'An unexpected error occurred. Please refresh the page at your earliest convenience.',
        )
      }
    }
  }, [lastMessage])

  const result: CardSocket = {
    records,
    activeRecords: [],
    inactiveRecords: [],
    connectionStatus,
    lastMessage,
    createRecord,
    updateRecordById: updateRecord,
    table: table,
    view: view,
    base: baserowData?.base,
    baserowErrorsState,
  }

  return result
}
