/* eslint-disable */
// @ts-nocheck

import { mix } from '../../core/mixins'
import { Registerable } from '../../core/registry'
import {
  baserowFormulaArrayTypeFilterMixin,
  hasEmptyValueFilterMixin,
  hasMultipleSelectOptionIdEqualMixin,
  hasNestedSelectOptionValueContainsFilterMixin,
  hasNestedSelectOptionValueContainsWordFilterMixin,
  hasNumericValueComparableToFilterMixin,
  hasSelectOptionIdEqualMixin,
  hasSelectOptionValueContainsFilterMixin,
  hasSelectOptionValueContainsWordFilterMixin,
  hasValueContainsFilterMixin,
  hasValueContainsWordFilterMixin,
  hasValueEqualFilterMixin,
  hasValueLengthIsLowerThanFilterMixin,
} from '../arrayFilterMixins'
import {
  genericHasAllValuesEqualFilter,
  genericHasValueContainsFilter,
} from '../utils/fieldFilters'

export class BaserowFormulaTypeDefinition extends Registerable {
  getIconClass() {
    throw new Error(
      'Not implemented error. This method should return the types icon.',
    )
  }

  getRowEditFieldComponent(field) {
    throw new Error(
      'Not implemented error. This method should return the types row edit component.',
    )
  }

  getFilterInputComponent(field, filterType) {
    return this.app.$registry
      .get('field', this.getFieldType())
      .getFilterInputComponent(field, filterType)
  }

  getRowEditArrayFieldComponent() {
    return null
  }

  parseFilterValue(field, value) {
    return this.app.$registry
      .get('field', this.getFieldType())
      .parseFilterValue(field, value)
  }

  formatFilterValue(field, value) {
    return this.app.$registry
      .get('field', this.getFieldType())
      .formatFilterValue(field, value)
  }

  getFunctionalGridViewFieldComponent() {
    return this.app.$registry
      .get('field', this.getFieldType())
      .getFunctionalGridViewFieldComponent()
  }

  getGridViewFieldComponent() {
    return this.app.$registry
      .get('field', this.getFieldType())
      .getGridViewFieldComponent()
  }

  getFieldType() {
    throw new Error(
      'Not implemented error. This method should return the types corresponding' +
        ' Baserow field type that should be used for things like sort indicators etc.',
    )
  }

  getSortOrder() {
    throw new Error(
      'Not implemented error. This method should return a number indicating how it ' +
        'should be sorted compared to other formula types when displayed.',
    )
  }

  getDocsResponseExample(field) {
    return this.app.$registry
      .get('field', this.getFieldType())
      .getDocsResponseExample(field)
  }

  getDocsDataType(field) {
    return this.app.$registry
      .get('field', this.getFieldType())
      .getDocsDataType(field)
  }

  prepareValueForCopy(field, value) {
    return this.app.$registry
      .get('field', this.getFieldType())
      .prepareValueForCopy(field, value)
  }

  parseInputValue(field, value) {
    return this.app.$registry
      .get('field', this.getFieldType())
      .parseInputValue(field, value)
  }

  getCardComponent() {
    return this.app.$registry
      .get('field', this.getFieldType())
      .getCardComponent()
  }

  getCanSortInView(field) {
    return true
  }

  canBeSortedWhenInArray(field) {
    return false
  }

  getItemIsInNestedValueObjectWhenInArray() {
    return true
  }

  /**
   * The extra modal is a component that can be opened by emitting the `show` event up.
   */
  getExtraModal() {
    return null
  }

  getSort(name, order, field) {
    const underlyingFieldType = this.app.$registry.get(
      'field',
      this.getFieldType(),
    )
    return underlyingFieldType.getSort(name, order, field)
  }

  _mapFormulaTypeToFieldType(formulaType) {
    return this.app.$registry.get('formula_type', formulaType).getFieldType()
  }

  getSortIndicator(field) {
    const underlyingFieldType = this.app.$registry.get(
      'field',
      this._mapFormulaTypeToFieldType(field.formula_type),
    )
    return underlyingFieldType.getSortIndicator()
  }

  mapToSortableArray(element) {
    return this.getItemIsInNestedValueObjectWhenInArray()
      ? element.value
      : element
  }

  toHumanReadableString(field, value) {
    const underlyingFieldType = this.app.$registry.get(
      'field',
      this.getFieldType(),
    )
    return underlyingFieldType.toHumanReadableString(field, value)
  }

  canRepresentDate() {
    return false
  }

  canGroupByInView() {
    return false
  }

  isEqual(field, value1, value2) {
    const underlyingFieldType = this.app.$registry.get(
      'field',
      this._mapFormulaTypeToFieldType(field.formula_type),
    )
    return underlyingFieldType.isEqual(field, value1, value2)
  }

  getRowValueFromGroupValue(field, value) {
    const underlyingFieldType = this.app.$registry.get(
      'field',
      this._mapFormulaTypeToFieldType(field.formula_type),
    )
    return underlyingFieldType.getRowValueFromGroupValue(field, value)
  }

  getGroupValueFromRowValue(field, value) {
    const underlyingFieldType = this.app.$registry.get(
      'field',
      this._mapFormulaTypeToFieldType(field.formula_type),
    )
    return underlyingFieldType.getGroupValueFromRowValue(field, value)
  }

  canRepresentFiles(field) {
    return false
  }

  toBaserowFormulaType(field) {
    return this.getType()
  }
}

export class BaserowFormulaTextType extends mix(
  hasEmptyValueFilterMixin,
  hasValueEqualFilterMixin,
  hasValueContainsFilterMixin,
  hasValueContainsWordFilterMixin,
  hasValueLengthIsLowerThanFilterMixin,
  BaserowFormulaTypeDefinition,
) {
  static getType() {
    return 'text'
  }

  getFieldType() {
    return 'text'
  }

  getIconClass() {
    return 'iconoir-text'
  }

  getSortOrder() {
    return 1
  }

  canBeSortedWhenInArray(field) {
    return true
  }

  canGroupByInView() {
    return true
  }
}

export class BaserowFormulaCharType extends mix(
  hasEmptyValueFilterMixin,
  hasValueEqualFilterMixin,
  hasValueContainsFilterMixin,
  hasValueContainsWordFilterMixin,
  hasValueLengthIsLowerThanFilterMixin,
  BaserowFormulaTypeDefinition,
) {
  static getType() {
    return 'char'
  }

  getFieldType() {
    return 'text'
  }

  getIconClass() {
    return 'iconoir-text'
  }

  getSortOrder() {
    return 1
  }

  canBeSortedWhenInArray(field) {
    return true
  }

  canGroupByInView() {
    return true
  }
}

export class BaserowFormulaNumberType extends mix(
  hasEmptyValueFilterMixin,
  hasValueContainsFilterMixin,
  hasNumericValueComparableToFilterMixin,

  BaserowFormulaTypeDefinition,
) {
  static getType() {
    return 'number'
  }

  getFieldType() {
    return 'number'
  }

  getIconClass() {
    return 'baserow-icon-hashtag'
  }

  getSortOrder() {
    return 2
  }

  canBeSortedWhenInArray(field) {
    return true
  }

  canGroupByInView() {
    return true
  }
}

export class BaserowFormulaBooleanType extends BaserowFormulaTypeDefinition {
  static getType() {
    return 'boolean'
  }

  getFieldType() {
    return 'boolean'
  }

  getIconClass() {
    return 'baserow-icon-circle-checked'
  }

  getSortOrder() {
    return 3
  }

  canBeSortedWhenInArray(field) {
    return true
  }

  canGroupByInView() {
    return true
  }

  getHasAllValuesEqualFilterFunction(field) {
    return (cellValue, filterValue) => {
      const parsedValue = this.parseInputValue(field, filterValue)
      return genericHasAllValuesEqualFilter(cellValue, parsedValue)
    }
  }

  getHasValueContainsFilterFunction(field) {
    return (cellValue, filterValue) => {
      const parsedValue = this.parseInputValue(field, filterValue)
      return genericHasValueContainsFilter(cellValue, parsedValue)
    }
  }

  getHasValueEqualFilterFunction(field) {
    return this.app.$registry
      .get('field', this.getFieldType())
      .getHasValueEqualFilterFunction(field)
  }

  parseInputValue(field, filterValue) {
    if (filterValue === '') {
      return false
    }
    return super.parseInputValue(field, filterValue)
  }
}

export class BaserowFormulaDateType extends mix(
  hasEmptyValueFilterMixin,
  hasValueContainsFilterMixin,
  BaserowFormulaTypeDefinition,
) {
  static getType() {
    return 'date'
  }

  getFieldType() {
    return 'date'
  }

  getIconClass() {
    return 'iconoir-calendar'
  }

  getSortOrder() {
    return 4
  }

  canRepresentDate() {
    return true
  }

  canBeSortedWhenInArray(field) {
    return true
  }

  mapToSortableArray(element) {
    return element.value
  }

  canGroupByInView() {
    return true
  }
}

export class BaserowFormulaDurationType extends BaserowFormulaTypeDefinition {
  static getType() {
    return 'duration'
  }

  getFieldType() {
    return 'duration'
  }

  getIconClass() {
    return 'iconoir-clock-rotate-right'
  }

  getSortOrder() {
    return 5
  }

  canGroupByInView() {
    return true
  }

  canBeSortedWhenInArray(field) {
    return true
  }
}

// Deprecated, use BaserowFormulaDurationType instead.
export class BaserowFormulaDateIntervalType extends BaserowFormulaTypeDefinition {
  static getType() {
    return 'date_interval'
  }

  getFieldType() {
    return 'text'
  }

  getIconClass() {
    return 'baserow-icon-history'
  }

  getSortOrder() {
    return 5
  }

  canGroupByInView() {
    return true
  }
}

// This type only exists in the frontend and only is referenced by a few weird frontend
// function defs which we want to show as a 'special' type in the GUI.
export class BaserowFormulaSpecialType extends BaserowFormulaTypeDefinition {
  static getType() {
    return 'special'
  }

  getFieldType() {
    return 'text'
  }

  getIconClass() {
    return 'baserow-icon-formula'
  }

  getSortOrder() {
    return 6
  }
}

export class BaserowFormulaInvalidType extends BaserowFormulaTypeDefinition {
  static getType() {
    return 'invalid'
  }

  getFieldType() {
    return 'text'
  }

  getIconClass() {
    return 'iconoir-warning-triangle'
  }

  getSortOrder() {
    return 9
  }

  getCanSortInView(field) {
    return false
  }
}

export class BaserowFormulaArrayType extends mix(
  baserowFormulaArrayTypeFilterMixin,
  BaserowFormulaTypeDefinition,
) {
  static getType() {
    return 'array'
  }

  getFieldType(field) {
    return 'text'
  }

  parseInputValue(field, value) {
    return this.getSubType(field)?.parseInputValue(field, value)
  }

  getIconClass() {
    return 'iconoir-list'
  }

  parseFilterValue(field, value) {
    const subType = this.getSubType(field)
    if (subType === null) {
      return value
    }
    return subType.parseFilterValue(field, value)
  }

  formatFilterValue(field, value) {
    const subType = this.getSubType(field)
    if (subType === null) {
      return value
    }
    return subType.formatFilterValue(field, value)
  }

  getSubType(field) {
    return this.app.$registry.get('formula_type', field.array_formula_type)
  }

  getFilterInputComponent(field, filterType) {
    return this.getSubType(field)?.getFilterInputComponent(field, filterType)
  }

  getSortOrder() {
    return 7
  }

  prepareValueForCopy(field, value) {
    const subType = this.getSubType(field)
    return value
      .map((v) => {
        return subType.prepareValueForCopy(
          field,
          subType.getItemIsInNestedValueObjectWhenInArray() ? v.value : v,
        )
      })
      .join(', ')
  }

  getDocsResponseExample(field) {
    if (field.array_formula_type !== null) {
      const subType = this.getSubType(field)
      const value = this.app.$registry
        .get('formula_type', field.array_formula_type)
        .getDocsResponseExample(field)
      if (subType.getItemIsInNestedValueObjectWhenInArray()) {
        return [{ id: 0, value }]
      } else {
        return [value]
      }
    } else {
      return null
    }
  }

  getDocsDataType(field) {
    return 'array'
  }

  getCanSortInView(field) {
    const subType = this.getSubType(field)
    return subType.canBeSortedWhenInArray(field)
  }

  canRepresentFiles(field) {
    const subType = this.getSubType(field)
    return subType.canRepresentFiles(field)
  }

  getSort(name, order, field) {
    const subType = this.getSubType(field)

    const innerSortFunction = subType.getSort(name, order, field)

    return (a, b) => {
      const valuesA = a[name].map(subType.mapToSortableArray.bind(subType))
      const valuesB = b[name].map(subType.mapToSortableArray.bind(subType))

      for (let i = 0; i < Math.max(valuesA.length, valuesB.length); i++) {
        let compared = 0

        const isAdefined = valuesA[i] || valuesA[i] === ''
        const isBdefined = valuesB[i] || valuesB[i] === ''

        if (isAdefined && isBdefined) {
          compared = innerSortFunction(
            { [name]: valuesA[i] },
            { [name]: valuesB[i] },
          )
        } else if (
          isAdefined &&
          (valuesB[i] === undefined || valuesB[i] === false)
        ) {
          compared = order === 'ASC' ? 1 : -1
        } else if (
          isBdefined &&
          (valuesA[i] === undefined || valuesA[i] === false)
        ) {
          compared = order === 'ASC' ? -1 : 1
        } else if (isAdefined && valuesB[i] === null) {
          compared = order === 'ASC' ? -1 : 1
        } else if (isBdefined && valuesA[i] === null) {
          compared = order === 'ASC' ? 1 : -1
        } else if (valuesA[i] === null && valuesB[i] === undefined) {
          compared = order === 'ASC' ? 1 : -1
        } else if (valuesA[i] === undefined && valuesB[i] === null) {
          compared = order === 'ASC' ? -1 : 1
        } else if (valuesA[i] === false && valuesB[i] !== false) {
          compared = order === 'ASC' ? 1 : -1
        } else if (valuesA[i] !== false && valuesB[i] === false) {
          compared = order === 'ASC' ? -1 : 1
        }

        if (compared !== 0) {
          return compared
        }
      }
    }
  }

  getSortIndicator(field) {
    const underlyingFieldType = this.app.$registry.get(
      'field',
      this._mapFormulaTypeToFieldType(field.array_formula_type),
    )
    return underlyingFieldType.getSortIndicator()
  }

  toHumanReadableString(field, value) {
    const subType = this.getSubType(field)
    return value
      .map((v) => {
        return subType.toHumanReadableString(
          field,
          subType.getItemIsInNestedValueObjectWhenInArray() ? v.value : v,
        )
      })
      .join(', ')
  }

  canGroupByInView() {
    return false
  }

  toBaserowFormulaType(field) {
    return this.getSubType(field)?.toBaserowFormulaType(field)
  }
}

export class BaserowFormulaFileType extends BaserowFormulaTypeDefinition {
  static getType() {
    return 'single_file'
  }

  getFieldType() {
    return 'file'
  }

  getIconClass() {
    return 'iconoir-empty-page'
  }

  getItemIsInNestedValueObjectWhenInArray() {
    return false
  }

  getSortOrder() {
    return 11
  }

  getCanSortInView(field) {
    return false
  }

  canBeSortedWhenInArray(field) {
    return false
  }

  canGroupByInView() {
    return false
  }

  getDocsResponseExample(field) {
    return {
      url: 'https://files.baserow.io/user_files/VXotniBOVm8tbstZkKsMKbj2Qg7KmPvn_39d354a76abe56baaf569ad87d0333f58ee4bf3eed368e3b9dc736fd18b09dfd.png',
      thumbnails: {
        tiny: {
          url: 'https://files.baserow.io/media/thumbnails/tiny/VXotniBOVm8tbstZkKsMKbj2Qg7KmPvn_39d354a76abe56baaf569ad87d0333f58ee4bf3eed368e3b9dc736fd18b09dfd.png',
          width: 21,
          height: 21,
        },
        small: {
          url: 'https://files.baserow.io/media/thumbnails/small/VXotniBOVm8tbstZkKsMKbj2Qg7KmPvn_39d354a76abe56baaf569ad87d0333f58ee4bf3eed368e3b9dc736fd18b09dfd.png',
          width: 48,
          height: 48,
        },
      },
      name: 'VXotniBOVm8tbstZkKsMKbj2Qg7KmPvn_39d354a76abe56baaf569ad87d0333f58ee4bf3eed368e3b9dc736fd18b09dfd.png',
      size: 229940,
      mime_type: 'image/png',
      is_image: true,
      image_width: 1280,
      image_height: 585,
      uploaded_at: '2020-11-17T12:16:10.035234+00:00',
    }
  }

  getDocsDataType(field) {
    return 'single_file'
  }

  prepareValueForCopy(field, value) {
    return `${value?.visible_name} (${value?.url})`
  }

  toHumanReadableString(field, value) {
    return value?.visible_name || ''
  }

  canRepresentFiles(field) {
    return true
  }
}

export class BaserowFormulaSingleSelectType extends mix(
  hasEmptyValueFilterMixin,
  hasSelectOptionIdEqualMixin,
  hasSelectOptionValueContainsFilterMixin,
  hasSelectOptionValueContainsWordFilterMixin,
  BaserowFormulaTypeDefinition,
) {
  static getType() {
    return 'single_select'
  }

  getFieldType() {
    return 'single_select'
  }

  getIconClass() {
    return 'baserow-icon-single-select'
  }

  getSortOrder() {
    return 8
  }

  getCanSortInView(field) {
    return true
  }

  canBeSortedWhenInArray(field) {
    return true
  }

  mapToSortableArray(element) {
    return element.value
  }

  canGroupByInView() {
    return false
  }
}

export class BaserowFormulaMultipleSelectType extends mix(
  hasEmptyValueFilterMixin,
  hasNestedSelectOptionValueContainsFilterMixin,
  hasNestedSelectOptionValueContainsWordFilterMixin,
  hasMultipleSelectOptionIdEqualMixin,
  BaserowFormulaTypeDefinition,
) {
  static getType() {
    return 'multiple_select'
  }

  getFieldType() {
    return 'multiple_select'
  }

  getIconClass() {
    return 'baserow-icon-multiple-select'
  }

  getSortOrder() {
    return 8
  }

  getCanSortInView(field) {
    return false
  }

  canBeSortedWhenInArray(field) {
    return false
  }

  mapToSortableArray(element) {
    return element.value
  }

  canGroupByInView() {
    return false
  }
}

export class BaserowFormulaMultipleCollaboratorsType extends mix(
  BaserowFormulaTypeDefinition,
) {
  static getType() {
    return 'multiple_collaborators'
  }

  getFieldType() {
    return 'multiple_collaborators'
  }

  getIconClass() {
    return 'iconoir-community'
  }

  getFilterInputComponent(field, filterType) {
    return null
  }

  getSortOrder() {
    return 8
  }

  getCanSortInView(field) {
    return false
  }

  canBeSortedWhenInArray(field) {
    return false
  }

  mapToSortableArray(element) {
    return element.value
  }

  canGroupByInView() {
    return false
  }
}

export class BaserowFormulaLinkType extends BaserowFormulaTypeDefinition {
  static getType() {
    return 'link'
  }

  getFieldType() {
    return 'text'
  }

  getIconClass() {
    return 'iconoir-link'
  }
  getSortOrder() {
    return 10
  }

  toHumanReadableString(field, value) {
    if (value?.label) {
      return `${value.label} (${value.url})`
    } else {
      return value.url
    }
  }

  prepareValueForCopy(field, value) {
    return this.toHumanReadableString(field, value)
  }

  getCanSortInView(field) {
    return false
  }

  canGroupByInView() {
    return false
  }
}

export class BaserowFormulaURLType extends mix(
  hasEmptyValueFilterMixin,
  hasValueEqualFilterMixin,
  hasValueContainsFilterMixin,
  hasValueContainsWordFilterMixin,
  hasValueLengthIsLowerThanFilterMixin,
  BaserowFormulaTypeDefinition,
) {
  static getType() {
    return 'url'
  }

  getFieldType() {
    return 'url'
  }

  getIconClass() {
    return 'iconoir-link'
  }

  getSortOrder() {
    return 10
  }

  toHumanReadableString(field, value) {
    if (_.isString(value)) {
      return value
    }
    if (value?.label) {
      return `${value.label} (${value.url})`
    } else {
      return value.url
    }
  }

  prepareValueForCopy(field, value) {
    return this.toHumanReadableString(field, value)
  }

  getCanSortInView(field) {
    return false
  }

  canGroupByInView() {
    return false
  }
}

export class BaserowFormulaButtonType extends BaserowFormulaLinkType {
  static getType() {
    return 'button'
  }
}
