/* eslint-disable */
// @ts-nocheck

import { Registry } from '../baserowImport/baserow/modules/core/registry'

export class BaserowRegistry extends Registry {
  protected registry: { [key: string]: { [key: string]: any } }

  constructor() {
    super()
    this.registry = {}
    this.registerNamespace('field')
    this.registerNamespace('viewFilter')
    this.registerNamespace('formula_type')
  }

  registerNamespace(namespace: string): void {
    this.registry[namespace] = {}
  }

  register(namespace: string, object: any): void {
    const type = object.getType()

    if (!Object.prototype.hasOwnProperty.call(this.registry, namespace)) {
      this.registry[namespace] = {}
    }
    this.registry[namespace][type] = object
  }

  registerFieldType(fieldType: any): void {
    this.register('field', fieldType)
  }

  registerViewFilterType(filterType: any): void {
    this.register('viewFilter', filterType)
  }

  registerFormulaType(formulaType: any): void {
    this.register('formula_type', formulaType)
  }

  get(namespace: string, type: string): any {
    if (!Object.prototype.hasOwnProperty.call(this.registry, namespace)) {
      throw new Error(
        `The namespace ${namespace} is not found in the registry.`,
      )
    }
    if (!Object.prototype.hasOwnProperty.call(this.registry[namespace], type)) {
      throw new Error(
        `The type ${type} is not found under namespace ${namespace} in the registry.`,
      )
    }
    return this.registry[namespace][type]
  }

  getAll(namespace: string): { [key: string]: any } {
    if (!Object.prototype.hasOwnProperty.call(this.registry, namespace)) {
      throw new Error(
        `The namespace ${namespace} is not found in the registry.`,
      )
    }
    return this.registry[namespace]
  }

  exists(namespace: string, type: string): boolean {
    if (!Object.prototype.hasOwnProperty.call(this.registry, namespace)) {
      return false
    }
    if (!Object.prototype.hasOwnProperty.call(this.registry[namespace], type)) {
      return false
    }
    return true
  }
}
